/* src/index.css */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Aplicar el fondo a toda la página */
body {
  background-color: rgb(40, 44, 51); /* Fondo de la página */
  font-family: 'Khula', sans-serif; /* Aplicar la fuente Nunito */
  color: var(--font); /* Fuente de color predeterminado */
}
